import React, { useState, useMemo } from 'react';
import { oneOf } from 'prop-types';
import {
  Box,
  Text,
  Button,
  Link,
  FormControl,
  Input,
  FormLabel,
  Select,
  InputGroup,
  InputLeftAddon,
  Center,
  useToast,
  Icon,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { RiFileCopy2Line, RiWhatsappLine } from 'react-icons/ri';
import { QuestionOutlineIcon } from 'react-icons/md';

import copyTextToClipboard from '@invitato/helpers/dist/copyTextToClipboard';
import getBroadcastText from '../getBroadcastText';

import { THE_BRIDE, HOSTNAME } from '@/constants';
import { ENABLE_GUEST_PERSONALIZATION, ENABLE_MULTI_LANGUAGE, ENABLE_SHIFT_TIME_RECEPTION } from '@/constants/feature-flags';
import {
  CUSTOM_MODE,
  LIST_BROADCAST,
  INPUT_PROPS,
  SELECT_PROPS,
  FORM_LABEL_OPTIONS,
  BUTTON_PROPS_OPEN,
} from '../types';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';
import ModalBroadcast from './ModalBroadcast';

const SHIFT_LIST = [
  {
    name: 'Holy Matrimony',
    value: '&shift=1',
  },
  {
    name: 'Holy Matrimony & Reception',
    value: '&shift=2',
  },
];

const PARTNER_LIST = [
  {
    name: '1 Tamu',
    value: '&partner=1',
  },
  {
    name: '2 Tamu',
    value: '&partner=2',
  },
  {
    name: '3 Tamu',
    value: '&partner=3',
  },
  {
    name: '4 Tamu',
    value: '&partner=4',
  },
  {
    name: '5 Tamu',
    value: '&partner=5',
  },
];

/**
 * function to render Custom Invitation Generator
 * @param {props} props
 * @returns {JSX.Element}
 */
function CustomScreen({ type }) {
  const [text, setText] = useState('');
  const [guestName, setGuestName] = useState('');
  const [guestNumber, setGuestNumber] = useState('');
  const [broadcastId, setBroadcastId] = useState('');
  const [lang, setLang] = useState('');
  const [shift, setShift] = useState('');
  const [partner, setPartner] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [modalState, setModalState] = useState('');
  const toast = useToast();

  const onClose = () => {
    setModalState('');
  };

  const isInvitation = CUSTOM_MODE.invitation === type;
  const link =
    HOSTNAME +
    '?' +
    `to=${encodeURIComponent(guestName)}` +
    `${isInvitation ? '&type=invitation' : ''}` +
    shift + partner + lang;

  /**
   * Function to get list of broadcast text option
   * @returns {Object[]}
   */
  const broadcastOptions = useMemo(() => LIST_BROADCAST.filter((x) => x.type.includes(type)), [
    type,
  ]);

  const generateTextBroadcast = () => {
    const finalText = broadcastId ? getBroadcastText({ id: broadcastId, link, guestName }) : '';

    setText(finalText);
    setShowResult(true);
  };

  const handleChangeBroadcastType = (e) => {
   
    setBroadcastId(e.target.value);
    setShowResult(false);
  };

  const handleCopyLink = async (customText) => {
    const result = await copyTextToClipboard(customText);
    
    if (result) {
      toast({
        status: 'success',
        title: 'Berhasil',
        description: 'Teks berhasil disalin di clipboard.',
      });
    } else {
      toast({
        title: 'Oops!',
        description: 'Terjadi kesalahan. Silahkan muat ulang browser Anda.',
      });
    }
  };

  const handleCopyText = async (customText) => {
    const result = await copyTextToClipboard(customText);
    if (text == '') {
      handlShowErrorToaster(
        'Anda belum memilih kalimat pengantar!',
      );
      return;
    }
    if (result) {
      toast({
        status: 'success',
        title: 'Berhasil',
        description: 'Teks berhasil disalin di clipboard.',
      });
    } else {
      toast({
        title: 'Oops!',
        description: 'Terjadi kesalahan. Silahkan muat ulang browser Anda.',
      });
    }
  };

  const handlShowErrorToaster = (text) => {
    toast({
      isClosable: true,
      duration: null,
      title: 'Oops!',
      status: 'error',
      description: text,
    });
  };

  const handleSentToWhatsApp = () => {
    if (!guestNumber) {
      handlShowErrorToaster(
        'Untuk menggunakan fitur kirim via WhatsApp, Anda perlu memasukan nomor handphone tamu yang sudah teregistrasi di WhatsApp.',
      );
      return;
    }

    if (!guestName) {
      handlShowErrorToaster('Nama tamu belum dimasukan!');
      return;
    }

    window.open(`https://wa.me/62${guestNumber}?text=${encodeURIComponent(text)}`, '_blank');
  };

  return (
    <Box color="black" fontFamily="Newsreader" paddingTop="24px" marginBottom="30px">
      <Box maxWidth="500px">
        <Text marginTop="16px" color='#1A202C' textTransform='uppercase'>The Wedding of</Text>
        <Text fontFamily="Newsreader" color='#1A202C' fontSize="3xl">
          {THE_BRIDE}
        </Text>
        {/* Section for Name value */}
        <FormControl>
          <FormLabel {...FORM_LABEL_OPTIONS}>Nama Tamu:</FormLabel>
          <Input
            {...INPUT_PROPS}
            placeholder="Nama tamu.."
            type="text"
            value={guestName}
            onChange={(e) => setGuestName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel {...FORM_LABEL_OPTIONS}>Nomor HP (Whatsapp):</FormLabel>
          <InputGroup size="sm">
            <InputLeftAddon {...INPUT_PROPS} bgColor="#E6DBD9">
              +62
            </InputLeftAddon>
            <Input
              {...INPUT_PROPS}
              placeholder="..."
              type="number"
              value={guestNumber}
              onChange={(e) => setGuestNumber(e.target.value)}
            />
          </InputGroup>
          <Text color="red" fontSize="xs" marginTop="8px" fontStyle="italic">*) Jika tidak diisi maka tombol kirim via WA akan non-aktif</Text>
        </FormControl>
        {/* Section for Shift - If enable shift mode and is invitation */}
        {ENABLE_SHIFT_TIME_RECEPTION && isInvitation && (
          <Box>
            <FormLabel {...FORM_LABEL_OPTIONS}>Pilih Jenis Undangan:</FormLabel>
            <Select {...SELECT_PROPS} value={shift} onChange={(e) => setShift(e.target.value)}>
              <option value="" style={{ color: 'black' }}>--- Pilih ---</option>
              {SHIFT_LIST.map((item) => {
                return (
                  <option key={item.value} value={item.value} style={{ color: 'black' }}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
          </Box>
        )}
        {/* Section for Partner - If any different partner option in RSVP */}
        {isInvitation && ENABLE_GUEST_PERSONALIZATION && (
           <Box>
           <FormLabel {...FORM_LABEL_OPTIONS}>Jumlah Tamu:</FormLabel>
           <Select {...SELECT_PROPS} value={partner} onChange={(e) => setPartner(e.target.value)}>
             <option style={{ color: 'black' }} value="">
               --- Pilih Jumlah Tamu ---
             </option>
             {PARTNER_LIST.map((item) => {
               return (
                 <option style={{ color: 'black' }} key={item.value} value={item.value}>
                   {item.name}
                 </option>
               );
             })}
           </Select>
         </Box>
        )}
        {ENABLE_MULTI_LANGUAGE && (
          <Box>
            <FormLabel {...FORM_LABEL_OPTIONS}>Bahasa</FormLabel>
            <Select {...SELECT_PROPS} value={lang} onChange={(e) => setLang(e.target.value)}>
              <option style={{ color: 'black' }} value={`&lang=id`}>
                Indonesia
              </option>
              <option style={{ color: 'black' }} value={`&lang=en`}>
                English
              </option>
            </Select>
          </Box>
        )}
        {/* Tipe text broadcast */}
        <Box>
          <FormLabel {...FORM_LABEL_OPTIONS}>Teks Kalimat Pengantar:</FormLabel>
          <Button onClick={() => setModalState('kalimatpengantar')}  {...DEFAULT_BUTTON_PROPS} bgColor="#F0EDE6" >
            <Text marginBottom="8px" marginLeft="-16px" fontSize="14px" marginTop="-24px" fontWeight="light" fontStyle="italic" >
              <Icon as={QuestionOutlineIcon} /> Apa itu Kalimat Pengantar?
            </Text>
           </Button>
          <Select {...SELECT_PROPS} marginTop="-16px" value={broadcastId} onChange={handleChangeBroadcastType}>
            <option value="" style={{ color: 'black' }} marginTop="10px">--- Pilih Teks Kalimat Pengantar ---</option>
            {broadcastOptions.map((item) => {
              return (
                <option key={item.value} value={item.value} style={{ color: 'black' }} >
                  {item.name}
                </option>
              );
            })}
          </Select>
        </Box>

        {/* Box Section */}
        <Flex justifyItems="end">
        <Spacer />
        <Button
          bgColor="#E6DBD9"
          fontWeight="bold"
          marginTop="24px"
          color='#1A202C'
          size="sm"
          border="1px"
          borderColor="black"
          onClick={generateTextBroadcast}
        >
          {isInvitation ? 'Buat Link Invitation' : 'Buat Link Announcement'}
        </Button>
        </Flex>
      </Box>

      {/* Show content share */}
      {showResult && (
        <Box>
          <Box
            fontSize="sm"
            bgColor="#E6DBD9"
            padding="16px 24px"
            margin="24px 0"
            borderRadius="20px"
            overflowY="auto"
          >
            {text ? <pre>{text}</pre> : <Text fontStyle="italic">{link}</Text>}
          </Box>
          {/* Link Action */}
          <Text fontSize="xl" color='#1A202C' fontFamily='Newsreader' fontWeight='semibold' letterSpacing='1px' margin="12px 0">Tahap Selanjutnya: </Text>          
          <Box fontSize="sm" >
            <Center>
              <Text margin="8px 0" color='#1A202C' >Coba Dulu Linknya Sebelum Dikirim</Text>
            </Center>
            <Center>
              <Link href={link} target="_blank">
              <Button {...BUTTON_PROPS_OPEN}>
                Uji Coba Link 
              </Button>
            </Link>
            </Center>
            <Center>
              <Text  marginTop="16px" color='#1A202C'>
                Pilihan 1:
              </Text>
            </Center>  
            <Center>
              <Text margin="0px 0 8px 0" color='#1A202C'>
                Salin Link Tanpa Teks Pengantar 
              </Text>
            </Center> 
            <Center> 
            <Button {...BUTTON_PROPS_OPEN} onClick={() => handleCopyLink(link)}>
              <RiFileCopy2Line style={{ marginRight: '4px' }} />              
                <Text cursor="pointer" >
                  Copy Link
                </Text>
              </Button>
            </Center>
            <Center>       
              <Text margin="16px 0 0px 0" color='#1A202C'>
                Pilihan 2:
              </Text>
            </Center>
            <Center>       
              <Text margin="0px 0 8px 0" color='#1A202C'>
                Salin Link + Teks Pengantar
              </Text>
            </Center>
            <Center>
            <Button {...BUTTON_PROPS_OPEN} onClick={() => handleCopyText(text)}> 
              <RiFileCopy2Line style={{ marginRight: '4px' }} />
              <Text cursor="pointer" >
                Copy Link + Teks Broadcast
              </Text>
            </Button>
            </Center>
            <Center>
              <Text margin="16px 0 0px 0" color='#1A202C'>
                Pilihan 3:
              </Text>
            </Center>
            <Center>
              <Text margin="0px 0 8px 0" color='#1A202C'>
                Kirim Link + Teks Langsung ke WA
              </Text>
            </Center>
            <Center>
            <Button {...BUTTON_PROPS_OPEN} onClick={handleSentToWhatsApp}>
              <RiWhatsappLine style={{ marginRight: '4px' }} />
              <Text cursor="pointer" >
                Kirim Link ke WhatsApp
              </Text>
            </Button>
            </Center>
          </Box>
        </Box>
      )}
      <ModalBroadcast
        visible={modalState === 'kalimatpengantar'}
        onClose={onClose}
      />
    </Box>
  );
}

CustomScreen.propTypes = {
  type: oneOf(['invitation', 'announcement']),
};

export default CustomScreen;
