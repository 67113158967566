import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Text, Center } from '@chakra-ui/react';
import { string, node } from 'prop-types';

import Favicon from './assets/favicon.png';
import { SEO_IMAGE, SEO_URL, SEO_DESCRIPTION, SEO_TITLE } from '@/constants';

import 'react-image-lightbox/style.css';
import './assets/styles.css';

function InternalMainLayout({ children, title, description }) {
  const finalTitle = title || SEO_TITLE;
  const finalDescription = description || SEO_DESCRIPTION;

  return (
    <Fragment>
      <Helmet>
        <title>{SEO_TITLE}</title>

        <link rel="icon" type="image/png" href={Favicon} />

        <meta property="og:title" content={finalTitle} />
        <meta property="og:image" content={SEO_IMAGE} />
        <meta property="og:url" content={SEO_URL} />
        <meta property="og:site_name" content={finalTitle} />
        <meta property="og:description" content={finalDescription} />
        <meta name="twitter:title" content={finalTitle} />
        <meta name="twitter:description" content={finalDescription} />
        <meta name="twitter:image" content={SEO_IMAGE} />
        <meta name="twitter:url" content={SEO_URL} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@idindrakusuma" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link
          href="https://fonts.googleapis.com/css2?family=Jost:wght@200;300;500&family=Pinyon+Script&family=Newsreader:wght@500;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      
      <Box id="page" maxWidth="100%" bgColor="#E6DBD9">
        <Box
          boxShadow="2xl"
          maxWidth="1024px"
          display="block"
          margin="auto"
          minHeight="100vh"
          //bgImage="url(https://user-images.githubusercontent.com/10141928/145224053-bb57d929-c360-47a4-900f-bdc623edc4da.jpg)"
          //bgSize="cover"
          //bgAttachment="fixed"
          //bgPos="center"
          bgColor=""
          padding="24px"
          //height="100vh"
          //overflowY="scroll"
        >
          <Box bgColor="#F0EDE6" border="1px" borderColor="black" marginTop="32px" marginBottom='45px' borderRadius="32px" padding="24px">
            {children}
          </Box>
        </Box>      
      </Box>
      <Box position='static' height='35px' bgColor="#F0EDE6" margin="auto" maxWidth="1024px" marginTop='-35px'>
        <Center>
          <Text color='#1A202C' fontSize='13px' marginTop='8px' fontFamily='Newsreader'> 
          © 2023 Invitato Studio | All Rights Reserved
          </Text>
        </Center>
      </Box>
    </Fragment>
  );
}

InternalMainLayout.propTypes = {
  title: string,
  description: string,
  children: node.isRequired,
};

InternalMainLayout.defaultProps = {
  title: '',
  description: '',
};

export default InternalMainLayout;
